<template>
  <div>
    <form>
      <div class="ps-block--vendor-dashboard">
        <div class="ps-block__header">
          <h3>Shop Image</h3>
          <div class="form-group">
            <img
              v-if="shop.image_path"
              width="300"
              height="200"
              :src="`${shop.image_path}`"
              alt="Shop Image"
            /><br />
            <div class="form-group__content">
              <input
                type="file"
                name="file"
                @change="setImage($event)"
                accept="image/*"
              />
              <small v-if="errors && errors.image" class="text-danger">
                {{ errors.image[0] }}
              </small>
            </div>
          </div>
          <!-- <button
          class="ps-btn mt-4"
          data-toggle="modal"
          data-target="#imageModal"
        >
          <i class="icon-sync2"></i>&nbsp;&nbsp;{{
            shop.image_path ? "Update Image" : "Add Image"
          }}
        </button> -->

          <div class="form-group">
            <button
              class="ps-btn btn btn-primary ls-button"
              @click.prevent="saveImage()"
            >
              <div v-if="!isLoading">
                <i class="icon-sync2"></i>&nbsp;&nbsp;{{
                  shop.image_path ? "Update Image" : "Add Image"
                }}
                <i class="icon icon-right-arrow ml-1"></i>
              </div>
              <div v-else>Save</div>

              <span
                v-if="isLoading"
                class="spinner-border text-light"
                role="status"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </form>

    <form>
      <div class="ps-form__content">
        <div class="row mt-3 mb-3" v-if="message">
          <div class="col-12">
            <small class="text-danger" v-if="isError">{{ message }}</small>
            <small class="text-success" v-else>{{ message }}</small>
          </div>
        </div>
        <div class="form-group">
          <label>Currency<sup>*</sup> </label>
          <div class="form-group__content">
            <select
              v-model="form.currency_code"
              class="form-control"
              id="currency_code"
            >
              <option value="USD">USD</option>
            </select>
            <small
              v-if="errors && errors.currency_code"
              class="currency_code text-danger"
            >
              {{ errors.currency_code[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <label>About Us<sup>*</sup> </label>
          <div class="form-group__content">
            <textarea
              class="form-control"
              v-model="form.about_us"
              id="about_us"
            ></textarea>
            <small v-if="errors && errors.about_us" class="text-danger">
              {{ errors.about_us[0] }}
            </small>
          </div>
        </div>
        <div class="form-group">
          <button class="ps-btn" @click.prevent="onSubmitOther()">
            {{ isLoading ? "Saving..." : "Save" }}
            <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
            <span
              v-if="isLoading"
              class="spinner-border text-light"
              role="status"
            ></span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import axios from "axios";
import global from "@/mixins/global";

export default {
  name: "MerchantSettings",
  data() {
    return {
      form: {
        about_us: null,
        currency_code: null,
      },
      image: null,
      isLoading: false,
      isError: false,
      message: "",
      errors: null,
    };
  },
  mixins: [global],
  mounted() {
    this.getShop();
  },
  methods: {
    getShop() {
      axios.get("shops/view").then((response) => {
        this.shop = response.data;
      });
    },

    onSubmit() {
      this.isLoading = true;
      let data = new FormData();
      data.append("image", this.image);
      axios
        .post("shops/", data)
        .then((response) => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },

    onSubmitOther() {
      //Need a submit for currency and about us
      //
      //
      // this.isLoading = true;
      // let data = new FormData();
      // data.append("image", this.image);
      // axios
      //   .post("shops/", data)
      //   .then((response) => {
      //     this.isLoading = false;
      //   })
      //   .catch((error) => {
      //     this.isLoading = false;
      //   });
    },

    saveImage() {
      this.loading = true;
      let data = new FormData();
      data.append("shop_id", this.shop.id);
      data.append("shop_image", this.image);

      axios
        .post("shops/save_image", data)
        .then((response) => {
          this.loading = false;
          if (response.status === 201 || response.status === 200) {
            location.reload();
          }
        })
        .catch((error) => {
          this.loading = false;
          this.error.response = error.response;
          console.log(error.response);
        });
    },

    setImage(event) {
      this.image = event.target.files[0];
      console.log(this.image);
    },

    async handleFileUpload(e) {
      this.uploadFile = e.target.files[0];
    },
  },
};
</script>
